import * as React from "react";
import { json, LoaderFunction } from "@remix-run/node";
import { useLoaderData, useParams } from "@remix-run/react";
import { type V2ThreadsLoader } from "~/routes/threads";
import { AuthorThreadListV2, ThreadListV2 } from "~/routes/threads/index";
import { leocache } from "~/utils/leocache";
import { Spinner } from "~/components/format/Spinner";
import classNames from "classnames";
import { useDebounce } from "~/hooks/useDebounce";

export const loader: LoaderFunction = async ({ params }) => {
  const author = params.account;

  const args = {
    author,
    sort: "latest",
    depth: false,
    page: "0"
  };

  const { threads, nextPage } = await leocache.getV2AuthorPageThreadFeed(args);

  return json({ threads, nextPage });
};

enum AccountFeeds {
  Latest = "latest",
  Oldest = "oldest",
  Trending = "trending",
  Disabled = "disabled"
}

export default function ProfileThreads() {
  const { account } = useParams();

  const { threads: initialThreads, nextPage: initialNextPage } =
    useLoaderData() as V2ThreadsLoader;

  const [selectedFeed, setSelectedFeed] = React.useState<AccountFeeds>(
    AccountFeeds.Latest
  );
  const [selectedOption, setSelectedOption] = React.useState(false); // Depth for threads true all false only parents.

  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const _searchQuery = useDebounce(searchQuery);

  // const [loadedThreads, loadMore, pushToStart, changeIndices, loading] =
  //   useThreads([], threadIndices);

  // React.useEffect(() => {
  //   if (selectedFeed === AccountFeeds.Disabled) return;
  //   void (async function () {
  //     const newIndices = await leocache.authorFeed(
  //       account || "",
  //       selectedFeed,
  //       selectedOption
  //     );
  //     changeIndices(newIndices);
  //   })();
  // }, [selectedFeed, selectedOption]);

  React.useEffect(() => {
    if (searchQuery === "" || searchQuery === " ") {
      setSelectedOption(false);
      return setSelectedFeed(AccountFeeds.Latest);
    }
    setSelectedFeed(AccountFeeds.Disabled);
    setSelectedOption(true);
    // void (async function () {
    //   const newIndices = await leocache.authorSearch(
    //     account || "",
    //     searchQuery
    //   );
    //   changeIndices(newIndices);
    // })();
  }, [searchQuery]);

  return (
    <React.Fragment>
      <ThreadsHeader
        account={account}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedFeed={selectedFeed}
        setSelectedFeed={setSelectedFeed}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />

      {initialThreads?.length === 0 ? (
        <div className="text-center text-zinc-400 dark:text-zinc-500 font-bold pt-24">
          No threads found.
        </div>
      ) : (
        <AuthorThreadListV2
          author={account}
          searchQuery={_searchQuery}
          depth={selectedOption}
          sort={selectedFeed}
          initialThreads={initialThreads}
          initialNextPage={initialNextPage}
        />
      )}
    </React.Fragment>
  );
}

function ThreadsHeader({
  account,
  searchQuery,
  selectedFeed,
  selectedOption,
  setSearchQuery,
  setSelectedFeed,
  setSelectedOption
}: any) {
  return (
    <div className="border-b border-pri dark:border-pri-d h-14 p-4 flex flex-row items-center gap-2 whitespace-nowrap overflow-y-hidden overflow-x-auto">
      <input
        type="text"
        id="default-search"
        className="flex flex-1 min-w-[200px] p-2
          text-sm text-gray-900 border border-pri dark:border-pri-d rounded-full bg-gray-50
        focus:border-acc dark:bg-inherit
        dark:placeholder-gray-400 dark:text-white
        dark:focus:ring-acc dark:focus:border-acc outline-none placeholder:text-gray-500 dark:placeholder:text-zinc-500 transition-colors duration-150"
        placeholder={`Search among threads of @${account}`}
        value={searchQuery}
        onChange={ev => setSearchQuery(ev.target.value ?? false)}
        autoComplete="off"
        autoCorrect="off"
      />
      <button
        className={classNames(
          "flex flex-1 justify-center border py-2 px-2 rounded-full text-sm border-pri dark:border-pri-d duration-150",
          {
            "border-acc dark:border-acc bg-acc/10 dark:bg-acc/10 text-acc":
              selectedFeed === AccountFeeds.Latest && !searchQuery
          }
        )}
        onClick={() => setSelectedFeed(AccountFeeds.Latest)}
      >
        Latest
      </button>
      <button
        className={classNames(
          "flex flex-1 justify-center border py-2 px-2 rounded-full text-sm border-pri dark:border-pri-d duration-150",
          {
            "border-acc dark:border-acc bg-acc/10 dark:bg-acc/10 text-acc":
              selectedFeed === AccountFeeds.Oldest && !searchQuery
          }
        )}
        onClick={() => setSelectedFeed(AccountFeeds.Oldest)}
      >
        Oldest
      </button>
      <button
        className={classNames(
          "flex flex-1 justify-center border py-2 px-2 rounded-full text-sm border-pri dark:border-pri-d duration-150",
          {
            "border-acc dark:border-acc bg-acc/10 dark:bg-acc/10 text-acc":
              selectedFeed === AccountFeeds.Trending && !searchQuery
          }
        )}
        onClick={() => setSelectedFeed(AccountFeeds.Trending)}
      >
        Trending
      </button>
      <button
        className={classNames(
          "flex flex-1 justify-center border border-pri dark:border-pri-d py-2 px-2 rounded-full text-sm duration-150",
          {
            "border-acc dark:border-acc bg-acc/10 dark:bg-acc/10 text-acc":
              selectedOption
          }
        )}
        onClick={() => setSelectedOption(option => !option)}
      >
        {searchQuery ? "Search" : selectedOption ? "All" : "Parents"}
      </button>
    </div>
  );
}
